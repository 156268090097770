table.responsive-table {

  @include medium-up {
    border-radius: 5px;
    border-collapse: inherit;
    border-spacing: 0;
    overflow: hidden;
    width: 100%;
  }



  .cell-heading {
    display: inline-block;
    width: 30%;
    font-weight: $bold;

    @include medium-up {
      display: none;
    }
  }

  .cell-content {
    display: inline-block;
    width: 70%;

    @include medium-up {
      display: block;
    }
  }

  thead {
    background-color: $table-head-background;

    tr {
      th {
        color: $table-head-text;
        text-transform: none;
        font-size: 18px;
        font-weight: 700;
        padding: 20px;
        border: 1px solid $table-head-border;
        text-align: left;

        @include medium-up {
          padding: 20px;
          border: 2px solid $table-head-border;

          &:nth-of-type(even) {
            border-left: 0;
            border-right: 0;
          }

          &:last-of-type {
            border-right: 2px solid $table-head-border;
          }
        }
      }
    }
  }

  tbody {

    tr {

      &:last-child {
        border-bottom: 0;
      }

      td {
        padding: 20px 0;
        border-bottom: 2px solid $table-border;
        font-size: 16px;

        @include medium-up {
          border-right: 2px solid $table-border;
          padding: 20px;
          border-left: 2px solid $table-border;

          &:nth-of-type(even) {
            border-left: 0;
            border-right: 0;
          }

          &:last-of-type {
            border-right: 2px solid $table-border;
          }
        }
      }
    }
  }
}