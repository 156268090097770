body.login,
body.login main {
  background-color: $primary-color;
  width: 100%;
  margin: 0;
  max-width: 100%;

  .login {
    &.custom {
      position: relative;

      .alert {
        max-width: 450px;
        width: 100%;
        background: white;
        padding: 10px 20px;
        margin: 0 auto 10px;
        overflow: hidden;
      }

      .align-center {
        text-align: center;
      }

      .card {
        max-width: 450px;
        width: 100%;
        background: white;
        margin: 0 auto 30px;
        overflow: hidden;

        .logo {
          background: rgba(0, 0, 0, .25);
          padding: 40px;

          h1 {
            margin-bottom: 0;
          }

          img {
            margin: auto;
            max-width: 251px;
            height: auto;
          }
        }

        .form-fields {
          padding: 50px;
        }

        .form-group {
          margin-bottom: 25px;

          &.form-submit {
            padding-top: 5px;
            margin-bottom: 19px;
          }

          label {
            color: #141414;
            font-weight: 700;
            line-height: 24px;
            display: block;
          }

          input[type=checkbox]:checked + label:after {
            top: -1px;
          }

          input.input {
            border: 2px solid #d9d9d9;
            width: 100%;
            padding: 15px 22px 10px;

            &::placeholder {
              color: #333333;
              font-family: $primary-font;
              font-weight: 700;
              opacity: .5;
            }

            &:hover,
            &:focus {
              outline: 0;
              border-color: #445569;
            }

            &:focus {
              &::placeholder {
                opacity: .25;
              }
            }
          }

          input[type="submit"] {
            padding: 13px 35px 7px;
            margin-left: 0;
            background: $form-submit-button-color;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 16px;
            color: $form-submit-button-text-color;
            letter-spacing: 1px;
            border: 0;

            &:hover,
            &:focus {
              background: $form-submit-button-hover-color;
              color: $form-submit-button-text-hover-color;
            }
          }
        }

        .cta {
          p {
            font-size: 12px;
          }
        }

        .form-cta {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
    }
  }

  .version a {
    display: block;
    color: #b2b2b2;
    font-size: 16px;
    text-align: center;
  }
}