/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.sr-only:focus, .sr-only:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left,
.align-left {
  text-align: left;
}

.text-right,
.align-right {
  text-align: right;
}

.text-center,
.align-center {
  text-align: center;
}

.text-justify,
.align-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 991px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 991px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 991px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
}
.accordions .accordion-title button {
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  position: relative;
  width: 100%;
  padding: 20px 82px 10px 0;
  margin: 0 0 10px 0;
  font-weight: 700;
  color: black;
  text-align: left;
  background: transparent;
  border: 0;
  border-bottom: 2px solid gray;
}
.accordions .accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 80px;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions .accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 32px;
  font-family: FontAwesome;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: black;
  text-align: center;
  text-decoration: inherit;
  content: "\f067";
  transform: translateY(-8px);
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:hover, .accordions .accordion-title button:focus {
  color: blue;
  background: transparent;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
}
.accordions .accordion-title button:hover span, .accordions .accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button, .accordions .accordion-title.is-active button:focus {
  color: blue;
  background: transparent;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
}
.accordions .accordion-title.is-active button span, .accordions .accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button span:after, .accordions .accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}

.accordion[data-arx-type=layer] {
  position: relative;
  margin-bottom: 15PX;
  background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc(100% / var(--arx-grid-columns) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0, 88, 251, 0.2);
}
.accordion[data-arx-type=layer]:before {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 2PX;
  font-size: 12PX;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(0, 88, 251, 0.04);
  border: 1px dashed rgba(0, 88, 251, 0.2);
  border-top: 0;
  border-right: 0;
  content: "Accordion";
}

.content-form {
  position: relative;
  overflow: hidden;
  margin-right: -15px;
  margin-left: -15px;
  /* recaptcha */
  /* small screens */
  /* medium screens */
  /* large screens */
}
.content-form .alert {
  margin-bottom: 20px;
  padding: 14px;
  border: 1px solid rgba(135, 206, 250, 0.65);
  background: rgba(135, 206, 250, 0.25);
  border-radius: 10px;
}
.content-form .alert.success {
  border: 1px solid rgba(144, 238, 144, 0.65);
  background: rgba(144, 238, 144, 0.25);
}
.content-form .alert.danger {
  border: 1px solid rgba(255, 99, 71, 0.65);
  background: rgba(255, 99, 71, 0.25);
}
.content-form .alert.warning {
  border: 1px solid rgba(255, 140, 0, 0.65);
  background: rgba(255, 140, 0, 0.25);
}
.content-form .alert.info {
  border: 1px solid rgba(32, 178, 170, 0.65);
  background: rgba(32, 178, 170, 0.25);
}
.content-form .errors,
.content-form .success {
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-bottom: 30px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: normal;
  color: red;
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.15);
}
.content-form .errors.wide,
.content-form .success.wide {
  margin-left: 0;
  width: 100%;
}
.content-form .errors h2,
.content-form .errors h3,
.content-form .errors p,
.content-form .success h2,
.content-form .success h3,
.content-form .success p {
  padding: 0;
}
.content-form .errors p,
.content-form .success p {
  font-size: 14px;
  line-height: 16px;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: normal;
}
.content-form .errors p:last-of-type,
.content-form .success p:last-of-type {
  margin-bottom: 0;
}
.content-form .errors ul,
.content-form .success ul {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: normal;
}
.content-form .errors ul li,
.content-form .success ul li {
  margin: 0 0 5px 0;
  padding: 0;
}
.content-form .errors ul li:before,
.content-form .success ul li:before {
  display: none;
}
.content-form .errors ul li:last-child,
.content-form .success ul li:last-child {
  margin: 0;
}
.content-form .success {
  color: green;
  border: 1px solid green;
  background: rgba(0, 128, 0, 0.15);
}
.content-form h2,
.content-form h3,
.content-form h4,
.content-form .form-header,
.content-form .form-footer {
  padding-right: 15px;
  padding-left: 15px;
}
.content-form h2 p:empty,
.content-form h3 p:empty,
.content-form h4 p:empty,
.content-form .form-header p:empty,
.content-form .form-footer p:empty {
  margin: 0;
}
.content-form p {
  padding-right: 15px;
  padding-left: 15px;
  clear: both;
}
.content-form p:empty {
  display: none;
}
.content-form p.note {
  font-size: 12px;
  line-height: 12px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.05em;
  font-style: italic;
}
.content-form hr {
  margin-left: 15px;
  width: calc(100% - 30px);
  clear: both;
}
.content-form .form-footer {
  margin-top: 30px;
}
.content-form .validation-summary {
  display: none;
}
.content-form fieldset {
  display: inline-block;
  width: 100%;
  min-width: min-content;
  border: 0;
  margin: 0 0 30px 0;
  padding: 0;
}
.content-form fieldset:last-of-type {
  margin-bottom: 0;
}
.content-form fieldset legend {
  display: block;
  margin: 0 0 6px 0;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  font-weight: 600;
}
@media (min-width: 1024px) {
  .content-form fieldset legend {
    margin: 0 0 10px 0;
  }
}
.content-form label {
  position: relative;
  display: inline-block;
  clear: both;
}
.content-form label.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}
.content-form .error-msg {
  position: relative;
  top: -30px;
  max-width: 100%;
  margin-top: 0;
  color: red;
}
.content-form .required label:after {
  position: relative;
  top: -7px;
  margin-left: 2px;
  content: "\f069";
  text-decoration: inherit;
  font-family: FontAwesome;
  font-size: 6px;
  font-weight: normal;
  font-style: normal;
  color: red;
}
.content-form input[type=text],
.content-form input[type=password],
.content-form input[type=date],
.content-form input[type=datetime],
.content-form input[type=datetime-local],
.content-form input[type=month],
.content-form input[type=week],
.content-form input[type=email],
.content-form input[type=number],
.content-form input[type=search],
.content-form input[type=tel],
.content-form input[type=time],
.content-form input[type=url],
.content-form input[type=file],
.content-form textarea,
.content-form select {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 0 30px 0;
  appearance: none;
}
.content-form input[type=text]:active, .content-form input[type=text]:focus,
.content-form input[type=password]:active,
.content-form input[type=password]:focus,
.content-form input[type=date]:active,
.content-form input[type=date]:focus,
.content-form input[type=datetime]:active,
.content-form input[type=datetime]:focus,
.content-form input[type=datetime-local]:active,
.content-form input[type=datetime-local]:focus,
.content-form input[type=month]:active,
.content-form input[type=month]:focus,
.content-form input[type=week]:active,
.content-form input[type=week]:focus,
.content-form input[type=email]:active,
.content-form input[type=email]:focus,
.content-form input[type=number]:active,
.content-form input[type=number]:focus,
.content-form input[type=search]:active,
.content-form input[type=search]:focus,
.content-form input[type=tel]:active,
.content-form input[type=tel]:focus,
.content-form input[type=time]:active,
.content-form input[type=time]:focus,
.content-form input[type=url]:active,
.content-form input[type=url]:focus,
.content-form input[type=file]:active,
.content-form input[type=file]:focus,
.content-form textarea:active,
.content-form textarea:focus,
.content-form select:active,
.content-form select:focus {
  outline: 0;
}
.content-form textarea {
  height: auto;
  min-height: 120px;
}
.content-form select::-ms-expand {
  display: none;
}
.content-form input[type=checkbox],
.content-form input[type=radio] {
  position: relative;
  float: left;
  width: 20px;
  height: 20px;
  max-height: auto;
  margin: 0 0 30px 0;
  border: 2px solid lightgray;
  background: white;
  appearance: none;
}
.content-form input[type=checkbox]:checked,
.content-form input[type=radio]:checked {
  border-color: darkgray;
  background: white;
  appearance: none;
}
.content-form input[type=checkbox]:checked:before,
.content-form input[type=radio]:checked:before {
  position: absolute;
  top: -1px;
  left: 0px;
  content: "\f00c";
  text-decoration: inherit;
  color: darkgray;
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
}
.content-form input[type=checkbox]:focus,
.content-form input[type=radio]:focus {
  border-color: darkgray;
  outline: none;
  appearance: none;
}
.content-form input[type=checkbox] + label,
.content-form input[type=radio] + label {
  position: relative;
  top: 0;
  display: inline-block;
  width: calc(100% - 20px);
  margin: 0 0 30px 0;
  padding-left: 10px;
}
.content-form input[type=radio] {
  border-radius: 50%;
  margin-bottom: 0;
}
.content-form input[type=radio] + label {
  top: -5px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
}
.content-form .radio-list ul {
  padding-left: 0;
}
.content-form .radio-list ul li {
  margin-bottom: 10px;
  padding-left: 0;
  border-left: 0;
}
.content-form .checkbox {
  overflow: hidden;
}
.content-form .action-buttons {
  overflow: hidden;
  margin: 30px 0;
}
.content-form input[type=submit] {
  margin-left: 15px;
  border: 0;
}
.content-form input[type=submit].btn-upload {
  margin: 0 0 30px 0;
}
.content-form [class*=sm-] + [class*=sm-] > input[type=submit],
.content-form [class*=sm-] + [class*=sm-] > input[type=submit],
.content-form [class*=sm-] + [class*=sm-] > input[type=submit],
.content-form [class*=sm-] + [class*=sm-] > input[type=submit],
.content-form [class*=sm-] + [class*=sm-] > input[type=submit] {
  margin-left: 0;
}
.content-form [class*=sm-] > p,
.content-form [class*=md-] > p,
.content-form [class*=lg-] > p,
.content-form [class*=xlg-] > p,
.content-form [class*=xxlg-] > p {
  padding: 0;
}
.content-form .g-recaptcha {
  margin: 0 0 30px 0;
  padding-right: 15px;
  padding-left: 15px;
}
.content-form .sm-last + div {
  clear: both;
}
.content-form .sm-full {
  clear: both;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.content-form .sm-half {
  float: left;
  clear: none;
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
.content-form .sm-third {
  float: left;
  clear: none;
  width: 33.333%;
  padding-right: 15px;
  padding-left: 15px;
}
.content-form .sm-two-thirds {
  float: left;
  clear: none;
  width: 66.666%;
  padding-right: 15px;
  padding-left: 15px;
}
.content-form .sm-quarter {
  float: left;
  clear: none;
  width: 25%;
  padding-right: 15px;
  padding-left: 15px;
}
.content-form .sm-three-quarters {
  float: left;
  clear: none;
  width: 75%;
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (min-width: 991px) {
  .content-form [class*=md-] {
    clear: none;
    width: auto;
  }
  .content-form [class~=sm-last] + div[class*=md-] {
    clear: none;
  }
  .content-form [class~=md-last] + div[class*=sm-] {
    clear: both;
  }
  .content-form .md-full {
    clear: none;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .md-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .md-third {
    float: left;
    clear: none;
    width: 33.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .md-two-thirds {
    float: left;
    clear: none;
    width: 66.6666666667%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .md-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .md-three-quarters {
    float: left;
    clear: none;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .content-form [class*=lg-] {
    clear: none;
    width: auto;
  }
  .content-form [class~=sm-last] + div[class*=lg-],
.content-form [class~=md-last] + div[class*=lg-] {
    clear: none;
  }
  .content-form [class~=lg-last] + div[class*=sm-],
.content-form [class~=lg-last] + div[class*=md-] {
    clear: both;
  }
  .content-form .lg-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .lg-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .lg-third {
    float: left;
    clear: none;
    width: 33.333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .lg-two-thirds {
    float: left;
    clear: none;
    width: 66.666%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .lg-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .lg-three-quarters {
    clear: none;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 1341px) {
  .content-form [class*=xlg-] {
    clear: none;
    width: auto;
  }
  .content-form [class~=sm-last] + div[class*=xlg-],
.content-form [class~=md-last] + div[class*=xlg-],
.content-form [class~=lg-last] + div[class*=xlg-] {
    clear: none;
  }
  .content-form [class~=xlg-last] + div[class*=sm-],
.content-form [class~=xlg-last] + div[class*=md-],
.content-form [class~=xlg-last] + div[class*=lg-] {
    clear: both;
  }
  .content-form .xlg-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xlg-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xlg-third {
    float: left;
    clear: none;
    width: 33.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xlg-two-thirds {
    float: left;
    clear: none;
    width: 66.6666666667%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xlg-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xlg-three-quarters {
    clear: none;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 1400px) {
  .content-form [class*=xxlg-] {
    clear: none;
    width: auto;
  }
  .content-form [class*=xxlg-last] + div {
    clear: both;
  }
  .content-form .xxlg-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xxlg-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xxlg-third {
    float: left;
    clear: none;
    width: 33.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xxlg-two-thirds {
    float: left;
    clear: none;
    width: 66.6666666667%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xxlg-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .content-form .xxlg-three-quarters {
    clear: none;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.tabs {
  position: relative;
  margin-bottom: 20px;
}
.tabs:not(.location-tabs).scroll-left:before, .tabs:not(.location-tabs).scroll-right:after {
  position: absolute;
  z-index: 99;
  width: 40px;
  height: 60px;
  content: "";
}
.tabs:not(.location-tabs).scroll-left:before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs:not(.location-tabs).scroll-right:after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs .tab-con {
  position: relative;
  padding: 0;
  margin-bottom: 2px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.tabs .tab-list {
  font-size: 14px;
  line-height: 17px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
  display: none;
  padding: 0;
  margin: 0;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  list-style: none;
}
.tabs .tab-list[role=tablist] {
  display: inline-block;
}
.tabs .tab-list li {
  display: inline-block;
  padding: 0;
  margin: 0 3px 0 0;
  white-space: nowrap;
}
.tabs .tab-list li:last-child {
  margin: 0;
}
.tabs .tab-list li a {
  position: relative;
  display: block;
  padding: 12px 19px;
  color: black;
  text-align: center;
  text-decoration: none;
  background: gray;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a[role=tab]:focus {
  color: white;
  background: black;
  outline: 0;
}
.tabs .tab-list li a[aria-selected] {
  padding: 12px 19px;
  color: black;
  background: gray;
  border: 0;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected][role=tab]:focus {
  color: black;
  background: gray;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

.tab-content[data-arx-type=layer] {
  position: relative;
  margin-bottom: 15PX;
  background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc(100% / var(--arx-grid-columns) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0, 88, 251, 0.2);
}
.tab-content[data-arx-type=layer]:before {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 2PX;
  font-size: 12PX;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(0, 88, 251, 0.04);
  border: 1px dashed rgba(0, 88, 251, 0.2);
  border-top: 0;
  border-right: 0;
  content: "Tab";
}

.sidebar-layout {
  overflow-x: hidden;
}
.sidebar-layout main {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
}
.sidebar-layout main .main-content {
  width: 100%;
  padding: 65px 20px 30px;
}
@media (min-width: 991px) {
  .sidebar-layout main .main-content {
    width: 70%;
    padding: 100px 70px;
  }
}
.sidebar-layout main .sidebar-content {
  width: 100%;
  background: #ffffff;
  padding: 30px 20px;
}
@media (min-width: 991px) {
  .sidebar-layout main .sidebar-content {
    padding: 100px 40px 100px 55px;
    width: 30%;
    position: relative;
  }
  .sidebar-layout main .sidebar-content:before {
    content: "";
    background: #ffffff;
    width: 100vw;
    height: 100%;
    position: absolute;
    display: inline-block;
    z-index: 0;
    top: 0;
  }
}
.sidebar-layout.left main .main-content {
  order: 1;
}
@media (min-width: 991px) {
  .sidebar-layout.left main .main-content {
    order: 2;
  }
}
.sidebar-layout.left main .sidebar-content {
  order: 2;
}
.sidebar-layout.left main .sidebar-content:before {
  right: 100%;
}
@media (min-width: 991px) {
  .sidebar-layout.left main .sidebar-content {
    order: 1;
  }
}
.sidebar-layout.right main .main-content {
  order: 1;
}
.sidebar-layout.right main .sidebar-content {
  order: 2;
}
.sidebar-layout.right main .sidebar-content:before {
  left: 100%;
}
.sidebar-layout.two .main-content {
  order: 1;
  width: 100%;
  padding: 65px 20px 30px;
}
@media (min-width: 991px) {
  .sidebar-layout.two .main-content {
    order: 2;
    width: 50%;
    padding: 100px 70px;
  }
}
.sidebar-layout.two .sidebar-content {
  width: 100%;
  padding: 30px 20px;
}
@media (min-width: 991px) {
  .sidebar-layout.two .sidebar-content {
    padding: 100px 40px 100px 55px;
    width: 25%;
  }
}
.sidebar-layout.two .sidebar-content.left {
  order: 2;
}
.sidebar-layout.two .sidebar-content.left:before {
  right: 100%;
}
@media (min-width: 991px) {
  .sidebar-layout.two .sidebar-content.left {
    order: 1;
  }
}
.sidebar-layout.two .sidebar-content.right {
  order: 3;
}
.sidebar-layout.two .sidebar-content.right:before {
  left: 100%;
}
@media (min-width: 991px) {
  .sidebar-layout.two .sidebar-content.right {
    order: 3;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 991px) {
  img {
    max-width: 100%;
  }
}
img[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 991px) {
  img[class*=left] {
    margin: 0 50px 20px 0;
  }
}
img[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 991px) {
  img[class*=right] {
    margin: 0 0 20px 50px;
  }
}
img[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 991px) {
  img[class*=center] {
    margin: 0 auto 20px auto;
  }
}

figure {
  display: table;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  margin: 0;
}
@media (min-width: 991px) {
  figure {
    max-width: 100%;
  }
}
figure img {
  display: block;
  margin: 0 auto;
}
figure img[class*=align] {
  margin: 0 auto;
  float: none;
}
@media (min-width: 991px) {
  figure img[class*=align] {
    margin: 0 auto;
    float: none;
  }
}
figure[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 991px) {
  figure[class*=left] {
    margin: 0 50px 20px 0;
  }
}
figure[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 991px) {
  figure[class*=right] {
    margin: 0 0 20px 50px;
  }
}
figure[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 991px) {
  figure[class*=center] {
    margin: 0 auto 20px auto;
  }
}
figure figcaption {
  margin-top: 1px;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: white;
  background: black;
  padding: 10px 25px;
  display: table-caption;
  caption-side: bottom;
}

.subpage {
  position: relative;
  overflow-x: hidden;
}
.subpage main {
  padding: 65px 20px 80px;
  position: relative;
}
.subpage main h1 {
  margin-top: 0;
}
@media (min-width: 991px) {
  .subpage main {
    padding: 100px 50px;
    max-width: 1600px;
    justify-content: center;
    margin: 0 auto;
  }
}

.banner-wrapper {
  background: url("/themes/athena/assets/dist/images/subpage-banner2.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
  position: relative;
  width: 100%;
}
.banner-wrapper:before {
  content: "";
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: absolute;
}
.banner-wrapper .title {
  margin: auto;
  position: absolute;
  padding: 0 20px;
  margin: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
}
@media (min-width: 991px) {
  .banner-wrapper .title {
    padding: 0;
  }
}
.banner-wrapper .title h1 {
  font-weight: 700;
  font-size: 46px;
  color: white;
}
@media (min-width: 991px) {
  .banner-wrapper .title h1 {
    max-width: 1600px;
    padding: 0 50px;
    margin: auto;
    width: 100%;
  }
}

@media (min-width: 991px) {
  table.responsive-table {
    border-radius: 5px;
    border-collapse: inherit;
    border-spacing: 0;
    overflow: hidden;
    width: 100%;
  }
}
table.responsive-table .cell-heading {
  display: inline-block;
  width: 30%;
  font-weight: 700;
}
@media (min-width: 991px) {
  table.responsive-table .cell-heading {
    display: none;
  }
}
table.responsive-table .cell-content {
  display: inline-block;
  width: 70%;
}
@media (min-width: 991px) {
  table.responsive-table .cell-content {
    display: block;
  }
}
table.responsive-table thead {
  background-color: #042b59;
}
table.responsive-table thead tr th {
  color: #ffffff;
  text-transform: none;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  border: 1px solid #36557a;
  text-align: left;
}
@media (min-width: 991px) {
  table.responsive-table thead tr th {
    padding: 20px;
    border: 2px solid #36557a;
  }
  table.responsive-table thead tr th:nth-of-type(even) {
    border-left: 0;
    border-right: 0;
  }
  table.responsive-table thead tr th:last-of-type {
    border-right: 2px solid #36557a;
  }
}
table.responsive-table tbody tr:last-child {
  border-bottom: 0;
}
table.responsive-table tbody tr td {
  padding: 20px 0;
  border-bottom: 2px solid #d9d9d9;
  font-size: 16px;
}
@media (min-width: 991px) {
  table.responsive-table tbody tr td {
    border-right: 2px solid #d9d9d9;
    padding: 20px;
    border-left: 2px solid #d9d9d9;
  }
  table.responsive-table tbody tr td:nth-of-type(even) {
    border-left: 0;
    border-right: 0;
  }
  table.responsive-table tbody tr td:last-of-type {
    border-right: 2px solid #d9d9d9;
  }
}

.tabs .tab-con {
  margin-bottom: 0;
  z-index: 1;
}
.tabs .tab-con .tab-list li:before {
  display: none;
}
.tabs .tab-con .tab-list li a {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #ececec;
  border-left: 2px solid #d9d9d9;
  border-top: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  padding: 15px 35px;
}
.tabs .tab-con .tab-list li a:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  left: 0;
}
.tabs .tab-con .tab-list li a:hover, .tabs .tab-con .tab-list li a:focus {
  background: #042b59;
  color: white;
}
.tabs .tab-con .tab-list li a[aria-selected] {
  background: #042b59;
  color: white;
  border-color: #042b59;
}
.tabs .tab-con .tab-list li a[aria-selected]:before {
  background: #042b59;
}
.tabs .tab-con .tab-list li a[aria-selected]:hover, .tabs .tab-con .tab-list li a[aria-selected]:focus {
  background: #042b59;
  color: white;
}
.tabs .tab-con .tab-list li a[aria-selected][role=tab]:focus {
  background: #042b59;
  color: white;
}
.tabs .tab-content {
  background: white;
  padding: 25px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  z-index: 0;
}
.tabs .tab-content:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  top: -2px;
  height: 2px;
  left: 0;
}
@media (min-width: 991px) {
  .tabs .tab-content {
    padding: 55px 0 25px;
  }
}

.accordions h2.accordion-title button {
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0 10px 40px;
  line-height: 24px;
  color: #042b59;
  border: 0;
}
.accordions h2.accordion-title button:after {
  content: "\f055";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 0;
  font-weight: 700;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  color: #dc9e5c;
}
.accordions h2.accordion-title button:hover, .accordions h2.accordion-title button:focus {
  color: #333333;
  border: 0;
}
.accordions h2.accordion-title button:hover:after, .accordions h2.accordion-title button:focus:after {
  color: #042b59;
}
.accordions h2.accordion-title button span {
  display: none;
}
.accordions h2.accordion-title.is-active button {
  border: 0;
  color: #042b59 !important;
}
.accordions h2.accordion-title.is-active button:after {
  content: "\f056";
  color: #dc9e5c;
}
.accordions h2.accordion-title.is-active button:focus {
  color: #042b59;
  border: 0;
}

.search-results .search-results-search {
  background: transparent !important;
}
.search-results .search-results-search input[type=search] {
  border: 1px solid #cccccc !important;
  border-radius: 50px;
}
.search-results .search-results-search .search-submit {
  padding: 15px;
  background: #dc9e5c;
  border-radius: 50px;
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  transition: 0.5s;
}
@media (min-width: 991px) {
  .search-results .search-results-search .search-submit {
    padding: 15px 60px;
  }
}
.search-results .search-results-search .search-submit:hover, .search-results .search-results-search .search-submit:focus {
  border-color: white;
  background: #001d3f;
  text-decoration: none;
}
.search-results .search-results-search .search-submit:visited {
  color: white;
}
.search-results .results {
  border: 0 !important;
}
.search-results .results tr {
  background: white;
}
.search-results .results tr td {
  padding: 35px 0 !important;
  border: 0;
  border-bottom: 1px solid #dde1e1 !important;
  background: white;
  color: #444542;
  text-align: left;
  font-size: 18px;
  line-height: 29px;
}
.search-results .results p {
  margin: 0;
}
.search-results .results span {
  display: block;
}
.search-results .results a {
  position: relative;
  display: inline !important;
  font-size: 18px;
}
.search-results .results:before {
  content: none;
}

.search-results {
  font-size: 14px;
  line-height: 22px;
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: normal;
}
.search-results .stats {
  margin: 30px 0;
}
.search-results .search-results-search {
  background: lightgray;
  overflow: hidden;
  padding: 0;
  margin-bottom: 30px;
}
.search-results .search-results-search input[type=search] {
  border: 0;
  padding: 0;
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 30px;
  padding: 0 8px;
  width: calc(100% - 118px);
  font-style: italic;
  float: left;
}
.search-results .search-results-search input[type=search]::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=search]:-moz-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=search]::-moz-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=search]:-ms-input-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=submit] {
  display: inline-block;
  border: 0;
  padding: 0 8px;
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 30px;
  width: 114px;
  text-align: center;
  float: right;
  text-decoration: none;
}
.search-results .search-results-search input[type=submit]:hover, .search-results .search-results-search input[type=submit]:focus {
  text-decoration: none;
}
.search-results .results {
  position: relative;
  padding-top: 20px;
}
.search-results .results:before {
  content: "";
  background: gray;
  position: absolute;
  top: -16px;
  width: 100%;
  height: 1px;
}
.search-results .pagination {
  border-top: 1px solid gray;
}
.search-results .pagination > td {
  padding: 16px 0 0 0;
}
.search-results .pagination span,
.search-results .pagination a {
  background: lightgray;
  color: gray;
  display: inline-block;
  text-decoration: none;
  padding: 3px 6px;
  border: 1px solid gray;
  font-size: 13px;
  line-height: 13px;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
}
.search-results .pagination a {
  color: blue;
}
.search-results .pagination a:visited {
  color: blue;
}
.search-results .pagination a:hover, .search-results .pagination a:focus {
  color: red;
}
.search-results th[scope=col] {
  display: none;
}
.search-results tr:nth-child(n+2):not(.pagination) > td {
  font-size: 16px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  padding-bottom: 20px;
}
.search-results tr:nth-child(n+2):not(.pagination) > td a {
  display: block;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  letter-spacing: 0.0625em;
  font-weight: 400;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.modal {
  border-radius: 10px;
}
.modal .modal-content {
  padding: 0;
  text-align: left;
  border-radius: 10px;
}
.modal .modal-content h1,
.modal .modal-content h2 {
  margin: 0;
  position: relative;
  color: #ffffff;
  z-index: 0;
  padding: 45px 40px;
}
.modal .modal-content h1:before,
.modal .modal-content h2:before {
  content: "";
  background: #042b59;
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
  z-index: -1;
}
.modal .modal-content p {
  padding: 45px 40px;
  font-size: 18px;
}
.modal .modal-content .close-modal {
  background-color: transparent;
  top: 10px;
  right: 10px;
}
.modal .modal-content .close-modal:hover:before, .modal .modal-content .close-modal:focus:before {
  opacity: 0.5;
}
.modal .modal-content .close-modal:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", FontAwesome;
  font-size: 21px;
  font-weight: 700;
  color: white;
}

.tabs .tab-con {
  margin-bottom: 0;
  z-index: 1;
}
.tabs .tab-con .tab-list li:before {
  display: none;
}
.tabs .tab-con .tab-list li a {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #ececec;
  border-left: 2px solid #d9d9d9;
  border-top: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  padding: 15px 35px;
}
.tabs .tab-con .tab-list li a:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  left: 0;
}
.tabs .tab-con .tab-list li a:hover, .tabs .tab-con .tab-list li a:focus {
  background: #042b59;
  color: white;
}
.tabs .tab-con .tab-list li a[aria-selected] {
  background: #042b59;
  color: white;
  border-color: #042b59;
}
.tabs .tab-con .tab-list li a[aria-selected]:before {
  background: #042b59;
}
.tabs .tab-con .tab-list li a[aria-selected]:hover, .tabs .tab-con .tab-list li a[aria-selected]:focus {
  background: #042b59;
  color: white;
}
.tabs .tab-con .tab-list li a[aria-selected][role=tab]:focus {
  background: #042b59;
  color: white;
}
.tabs .tab-content {
  background: white;
  padding: 25px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  z-index: 0;
}
.tabs .tab-content:before {
  content: "";
  background: #d9d9d9;
  position: absolute;
  width: 100%;
  top: -2px;
  height: 2px;
  left: 0;
}
@media (min-width: 991px) {
  .tabs .tab-content {
    padding: 55px 0 25px;
  }
}

.content-form {
  margin: 0;
}
.content-form .form-header p {
  padding: 0;
}
.content-form fieldset {
  width: 100%;
}
.content-form input[type=text],
.content-form input[type=file],
.content-form textarea,
.content-form select {
  display: block;
  margin-top: 5px;
  margin-bottom: 30px;
  padding: 10px;
  width: 100%;
  border: 2px solid #cccccc;
  background: transparent;
  color: #333333;
  font-weight: 300;
  font-size: 18px;
  border-radius: 50px;
}
.content-form input[type=text]:focus,
.content-form input[type=file]:focus,
.content-form textarea:focus,
.content-form select:focus {
  outline: none;
  border-color: #042b59;
}
.content-form textarea {
  padding: 14px;
  font-style: normal;
  font-size: 18px;
  border-radius: 10px;
}
.content-form label {
  color: black;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
}
.content-form select {
  margin-top: 5px;
  width: 100%;
  border: 2px solid #cccccc;
  background: transparent;
  color: #333333;
  font-weight: 300;
  font-size: 18px;
  background: url("/themes/athena/assets/dist/images/Down-Arrow.png") no-repeat;
  background-position: 98% 50%;
  background-size: 12px;
  padding-right: 10px;
}
.content-form select:focus {
  outline: none;
  border-color: #042b59;
}
.content-form input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.content-form input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  font-weight: normal;
  margin-bottom: 15px;
}
.content-form input[type=checkbox] + label:before, .content-form input[type=checkbox] + label:after {
  position: absolute;
  display: inline-block;
  content: "";
}
.content-form input[type=checkbox] + label:before {
  top: 0px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #cccccc;
  border-radius: 5px;
}
.content-form input[type=checkbox] + label:after {
  top: 0;
  left: 0;
  content: none;
}
.content-form input[type=checkbox]:checked + label:after {
  content: "\f00c";
  color: white;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 14px;
  left: 3px;
  top: 2px;
}
.content-form input[type=checkbox]:checked + label:before {
  content: "";
  background: #042b59;
}
.content-form input[type=checkbox]:focus + label:before {
  outline: 5px auto -webkit-focus-ring-color;
}
.content-form button,
.content-form html input[type=button],
.content-form input[type=reset],
.content-form input[type=submit] {
  margin-left: 0;
  padding: 15px 40px;
  max-width: 300px;
  width: auto;
  width: 100%;
  border: 1px solid black;
  background: black;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  cursor: pointer;
  -webkit-appearance: button;
}
.content-form button:hover, .content-form button:focus,
.content-form html input[type=button]:hover,
.content-form html input[type=button]:focus,
.content-form input[type=reset]:hover,
.content-form input[type=reset]:focus,
.content-form input[type=submit]:hover,
.content-form input[type=submit]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  border-color: black;
  background: black;
  color: black;
}
.content-form .hide {
  display: none;
}
.content-form .radio-list {
  margin-top: 30px;
}
.content-form .radio-list legend {
  padding: 0;
}
.content-form .radio-list input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.content-form .radio-list input[type=radio] + label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  font-weight: normal;
  padding-top: 5px;
  margin-top: 5px;
}
.content-form .radio-list input[type=radio] + label:before {
  position: absolute;
  display: inline-block;
  font-weight: 500;
  left: 0;
  color: #042b59;
  font-size: 20px;
  content: "";
  width: 21px;
  top: 6px;
  height: 21px;
  border: 2px solid #cccccc;
  border-radius: 25px;
}
.content-form .radio-list input[type=radio]:checked + label:after {
  content: "\f111";
  color: #042b59;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  left: 6px;
  top: 6px;
  position: absolute;
}
.content-form .radio-list input[type=radio]:focus + label:before {
  outline: 5px auto -webkit-focus-ring-color;
}
.content-form .radio-list ul {
  padding: 10px 0;
}
.content-form .radio-list ul li {
  margin-bottom: 0;
}
.content-form .radio-list ul li:before {
  display: none;
}
.content-form .span-full {
  display: inline-block;
  margin-bottom: 10px;
  padding-right: 0;
  width: calc(100% - 15px);
}
.content-form .span-2 {
  display: inline-block;
  padding-right: 25px;
  width: calc((100% - 5px) / 2);
}
.content-form .span-2:nth-of-type(2n) {
  padding-right: 0 !important;
}
.content-form .span-3 {
  display: block;
  width: 100%;
}
@media (min-width: 991px) {
  .content-form .span-3 {
    display: inline-block;
    padding-right: 25px;
    width: calc((100% - 5px) / 3);
  }
  .content-form .span-3:nth-of-type(3n) {
    padding-right: 0 !important;
  }
}
.content-form .span-4 {
  display: block;
  width: 100%;
}
@media (min-width: 991px) {
  .content-form .span-4 {
    display: inline-block;
    padding-right: 25px;
    width: calc((100% - 5px) / 4);
  }
  .content-form .span-4:nth-of-type(4n) {
    padding-right: 0 !important;
  }
}
.content-form .span-5 {
  display: block;
  width: 100%;
}
@media (min-width: 991px) {
  .content-form .span-5 {
    display: inline-block;
    padding-right: 25px;
    width: calc((100% - 5px) / 5);
  }
  .content-form .span-5:nth-of-type(5n) {
    padding-right: 0 !important;
  }
}
.content-form .col-last {
  clear: right;
  margin-right: 0;
}
.content-form .error-msg {
  position: relative;
  right: 0;
  bottom: 0;
  float: left;
}

.required label:after {
  font-family: "Font Awesome 5 Free";
  content: "\f069";
  font-weight: 600;
  color: red;
}

.form-builder-submit-controls input[type=submit] {
  padding: 15px 40px;
  margin-left: 0;
  background: #042b59;
  border: 1px solid #343434;
  cursor: pointer;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 700;
}
.form-builder-submit-controls input[type=submit]:hover, .form-builder-submit-controls input[type=submit]:focus {
  background: #343434;
  color: #ffffff;
}

.trooper-form-container {
  display: flex;
  flex-wrap: wrap;
}

.trooper-form-left-container {
  width: 45%;
  padding: 0 3.5em;
  display: flex;
}
@media (max-width: 950px) {
  .trooper-form-left-container {
    width: 100%;
    padding: 2em;
  }
}
.trooper-form-left-container .trooper-hero-img {
  max-width: 600px;
  width: 90%;
  margin: auto;
}

.trooper-form-right-container {
  width: 55%;
}
@media (max-width: 950px) {
  .trooper-form-right-container {
    width: 100%;
    padding: 2em;
  }
}

.trooper-register-title,
.trooper-register-subtitle {
  max-width: 800px;
  margin: 0 auto;
}

.trooper-register-subtitle {
  font-size: 18px;
  margin-top: 0.5em;
  font-weight: 500;
}

.troopers-register-form {
  max-width: 800px;
  margin: 0 auto;
}
.troopers-register-form .register-input-container {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 2em;
  padding-right: 1em;
}
.troopers-register-form .register-input-container .register-label,
.troopers-register-form .register-input-container .profile-label {
  width: 100%;
  font-weight: 600;
  color: #333333;
  font-size: 18px;
}
.troopers-register-form .register-input-container .register-textbox,
.troopers-register-form .register-input-container .profile-textbox,
.troopers-register-form .register-input-container .profile-dropdown {
  padding: 0.5em;
  border-radius: 6px;
  margin-top: 0.5em;
  outline: none;
  border-style: solid;
  border-width: 1px;
  width: 100%;
}
.troopers-register-form input[type=text],
.troopers-register-form input[type=password] {
  transition: border-color 0.3s ease-in-out;
}
.troopers-register-form input[type=text]:hover, .troopers-register-form input[type=text]:focus,
.troopers-register-form input[type=password]:hover,
.troopers-register-form input[type=password]:focus {
  border-color: #042b59;
}
.troopers-register-form input[type=submit] {
  background: #042b59;
  color: white;
  padding: 1em 1.5em;
  border-radius: 50px;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-top: 2em;
}
.troopers-register-form input[type=submit]:hover, .troopers-register-form input[type=submit]:focus {
  background: #001d3f;
}
.troopers-register-form span {
  color: red;
  font-weight: 600;
  width: 100%;
  margin-top: 0.5em;
}
.troopers-register-form .troopers-only-btn {
  background: #042b59;
  color: white;
  padding: 0.75em 1.5em;
  border-radius: 50px;
  border-radius: 3.125rem;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 0 auto;
}
.troopers-register-form .troopers-only-btn.login {
  background: white;
  color: #042b59;
  border: 2px solid #042b59;
}
.troopers-register-form .decision-divider:before, .troopers-register-form .decision-divider:after {
  background-color: #a9a9a9;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 10%;
}
.troopers-register-form .decision-divider:before {
  right: 0.5em;
  margin-left: -50%;
}
.troopers-register-form .decision-divider:after {
  left: 0.5em;
  margin-right: -50%;
}

.news-posts {
  position: relative;
}
.news-posts .news-post {
  width: 100%;
  border-bottom: 2px dashed #e6e6e6;
  padding-bottom: 25px;
}
.news-posts .news-post:last-of-type {
  border: none;
}
.news-posts .news-post .news-post-headline a {
  border: none;
  color: #042b59;
}
.news-posts .news-post .news-post-headline a:visited {
  color: #042b59;
}
.news-posts .news-post .news-post-image {
  display: none;
}

.news-search {
  display: flex;
  width: 100%;
  padding-bottom: 30px;
}
.news-search a {
  padding: 15px 35px;
  margin-left: 0;
  background: #042b59;
  border: 1px solid #042b59;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
  color: white;
}
.news-search a:hover, .news-search a:focus {
  background: #dc9e5c;
  border-color: #dc9e5c;
  color: #343434;
  text-decoration: none;
}
.news-search input[type=text] {
  display: block;
  padding: 10px;
  width: 100%;
  margin-right: 10px;
  border: 1px solid #cccccc;
  background: transparent;
  color: #333333;
  font-weight: 300;
  font-size: 16px;
  border-radius: 5px;
}
.news-search input[type=text]:focus {
  outline: none;
  border-color: black;
}

body.login,
body.login main {
  background-color: #042b59;
  width: 100%;
  margin: 0;
  max-width: 100%;
}
body.login .login.custom,
body.login main .login.custom {
  position: relative;
}
body.login .login.custom .alert,
body.login main .login.custom .alert {
  max-width: 450px;
  width: 100%;
  background: white;
  padding: 10px 20px;
  margin: 0 auto 10px;
  overflow: hidden;
}
body.login .login.custom .align-center,
body.login main .login.custom .align-center {
  text-align: center;
}
body.login .login.custom .card,
body.login main .login.custom .card {
  max-width: 450px;
  width: 100%;
  background: white;
  margin: 0 auto 30px;
  overflow: hidden;
}
body.login .login.custom .card .logo,
body.login main .login.custom .card .logo {
  background: rgba(0, 0, 0, 0.25);
  padding: 40px;
}
body.login .login.custom .card .logo h1,
body.login main .login.custom .card .logo h1 {
  margin-bottom: 0;
}
body.login .login.custom .card .logo img,
body.login main .login.custom .card .logo img {
  margin: auto;
  max-width: 251px;
  height: auto;
}
body.login .login.custom .card .form-fields,
body.login main .login.custom .card .form-fields {
  padding: 50px;
}
body.login .login.custom .card .form-group,
body.login main .login.custom .card .form-group {
  margin-bottom: 25px;
}
body.login .login.custom .card .form-group.form-submit,
body.login main .login.custom .card .form-group.form-submit {
  padding-top: 5px;
  margin-bottom: 19px;
}
body.login .login.custom .card .form-group label,
body.login main .login.custom .card .form-group label {
  color: #141414;
  font-weight: 700;
  line-height: 24px;
  display: block;
}
body.login .login.custom .card .form-group input[type=checkbox]:checked + label:after,
body.login main .login.custom .card .form-group input[type=checkbox]:checked + label:after {
  top: -1px;
}
body.login .login.custom .card .form-group input.input,
body.login main .login.custom .card .form-group input.input {
  border: 2px solid #d9d9d9;
  width: 100%;
  padding: 15px 22px 10px;
}
body.login .login.custom .card .form-group input.input::placeholder,
body.login main .login.custom .card .form-group input.input::placeholder {
  color: #333333;
  font-family: "Red Hat Text", sans-serif;
  font-weight: 700;
  opacity: 0.5;
}
body.login .login.custom .card .form-group input.input:hover, body.login .login.custom .card .form-group input.input:focus,
body.login main .login.custom .card .form-group input.input:hover,
body.login main .login.custom .card .form-group input.input:focus {
  outline: 0;
  border-color: #445569;
}
body.login .login.custom .card .form-group input.input:focus::placeholder,
body.login main .login.custom .card .form-group input.input:focus::placeholder {
  opacity: 0.25;
}
body.login .login.custom .card .form-group input[type=submit],
body.login main .login.custom .card .form-group input[type=submit] {
  padding: 13px 35px 7px;
  margin-left: 0;
  background: #042b59;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 1px;
  border: 0;
}
body.login .login.custom .card .form-group input[type=submit]:hover, body.login .login.custom .card .form-group input[type=submit]:focus,
body.login main .login.custom .card .form-group input[type=submit]:hover,
body.login main .login.custom .card .form-group input[type=submit]:focus {
  background: #343434;
  color: #ffffff;
}
body.login .login.custom .card .cta p,
body.login main .login.custom .card .cta p {
  font-size: 12px;
}
body.login .login.custom .card .form-cta,
body.login main .login.custom .card .form-cta {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
body.login .version a,
body.login main .version a {
  display: block;
  color: #b2b2b2;
  font-size: 16px;
  text-align: center;
}

.gallery h2,
.gallery p {
  display: none;
}