.news-posts {
  position: relative;

  .news-post {
    width: 100%;
    border-bottom: 2px dashed $news-item-border-bottom-color;
    padding-bottom: 25px;

    &:last-of-type {
      border: none;
    }

    .news-post-headline {
      a {
        border: none;
        color: $primary-color;

        &:visited {
          color: $primary-color;
        }
      }
    }

    .news-post-image {
      display: none;
    }
  }
}

.news-search {
  display: flex;
  width: 100%;
  padding-bottom: 30px;

  a {
    padding: 15px 35px;
    margin-left: 0;
    background: $primary-color;
    border: 1px solid $primary-color;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    color: white;

    &:hover,
    &:focus {
      background: $tan;
      border-color: $tan;
      color: #343434;
      text-decoration: none;
    }
  }

  input[type='text'] {
    display: block;
    padding: 10px;
    width: 100%;
    margin-right: 10px;
    border: 1px solid $form-field-border-color;
    background: transparent;
    color: $form-field-text-color;
    font-weight: 300;
    font-size: 16px;
    border-radius: 5px;

    &:focus {
      outline: none;
      border-color: black;
    }
  }
}