.accordions {
  h2.accordion-title {

    button {
      font-size: 20px;
      font-weight: $bold;
      padding: 20px 0 10px 40px;
      line-height: 24px;
      color: $accordion-title;
      border: 0;

      &:after {
        content: '\f055';
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        left: 0;
        font-weight: $bold;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        font-size: 24px;
        display: inline-flex;
        align-items: center;
        color: $accordion-span;
      }

      &:hover,
      &:focus {
        color: $accordion-title-hover;
        border: 0;

        &:after {
          color: $accordion-span-hover;
        }
      }

      span {
        display: none;
      }
    }

    &.is-active {
      button {
        border: 0;
        color: $accordion-title !important;

        &:after {
          content: '\f056';
          color: $accordion-span;
        }

        &:focus {
          color: $accordion-title;
          border: 0;
        }
      }
    }
  }
}