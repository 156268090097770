.banner-wrapper {
  background: url('/themes/athena/assets/dist/images/subpage-banner2.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    background: rgba(0, 0, 0, .25);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .title {
    margin: auto;
    position: absolute;
    padding: 0 20px;
    margin: auto;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    @include medium-up {
      padding: 0;
    }

    h1 {
      font-weight: $bold;
      font-size: 46px;
      color: $subpage-banner-heading-color;
      @include medium-up {
        max-width: 1600px;
        padding: 0 50px;
        margin: auto;
        width: 100%;
      }
    }
  }
}