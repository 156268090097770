.search-results {
  .search-results-search {
    background: transparent !important;

    input[type='search'] {
      border: 1px solid $form-field-border-color !important;
      border-radius: 50px;
    }

    .search-submit {
      padding: 15px;
      background: $button-color;
      border-radius: 50px;
      color: white;
      text-decoration: none;
      letter-spacing: 1px;
      transition: .5s;
      @include medium-up {
        padding: 15px 60px;
      }

      &:hover,
      &:focus {
        border-color: white;
        background: $primary-darker-color;
        text-decoration: none;
      }

      &:visited {
        color: white;
      }
    }
  }

  .results {
    border: 0 !important;

    tr {
      background: white;

      td {
        padding: 35px 0 !important;
        border: 0;
        border-bottom: 1px solid #dde1e1 !important;
        background: white;
        color: #444542;
        text-align: left;
        font-size: 18px;
        line-height: 29px;
      }
    }

    p {
      margin: 0;
    }

    span {
      display: block;
    }

    a {
      position: relative;
      display: inline !important;
      font-size: 18px;
    }

    &:before {
      content: none;
    }
  }
}